// hooks/use-fetch-course.ts
import { useState, useEffect } from 'react';

interface Course {
    image: string;
    description: string;
    price: string;
    promotionPrice: string;
}

const useFetchCourse = (channel?: string, course?: string) => {
    const [courseData, setCourseData] = useState<Course | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (channel && course) {
            const fetchCourse = async () => {
                try {
                    const response = await fetch(`/course-info/${channel}/${course}.json`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch course data');
                    }
                    const data = await response.json();
                    setCourseData(data);
                } catch (err) {
                    if (err instanceof Error) {
                        setError(err.message);
                    } else {
                        setError('An unknown error occurred');
                    }
                } finally {
                    setLoading(false);
                }
            };

            fetchCourse();
        }
    }, [channel, course]);

    return { courseData, loading, fetchCourseError: error };
};

export default useFetchCourse;
