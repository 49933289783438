import { useEffect, useRef } from 'react';
import { useVideoProgressStore } from './stores/video-progress-store';
import { useUpdateVideoProgress } from './hooks/use-video-progress';

interface VideoPlayerProps {
    channel: string;
    course: string;
    video: string;
    onEnd: (props: { totalLength: number, progress: number }) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ channel, course, video, onEnd }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const playerRef = useRef<any>(null); // Reference to the videojs player instance
    const { mutate: trackProgress } = useUpdateVideoProgress();
    const videoProgress = useVideoProgressStore((state) => state.videoProgress);
    const setVideoProgress = useVideoProgressStore((state) => state.setVideoProgress);
    const startProgress = videoProgress[video]?.progress || 0;
    const totalLength = videoProgress[video]?.totalLength || 0;
    const previousVideoRef = useRef<string | null>(null);
    let userPaused = false;

    useEffect(() => {
        if (previousVideoRef.current && previousVideoRef.current !== video) {
            const previousVideo = previousVideoRef.current;
            const progress = playerRef.current?.currentTime() || 0;
            const totalLength = playerRef.current?.duration() || 0;
            setVideoProgress(previousVideo, progress, totalLength);
        }
        previousVideoRef.current = video;

        if (videoRef.current) {
            if (!playerRef.current) {
                // @ts-ignore
                const player = videojs(videoRef.current, {
                    controls: true,
                    autoplay: true,
                    preload: 'auto',
                    fluid: true,
                    playsinline: true,
                });

                player.hlsQualitySelector({
                    displayCurrentQuality: true,
                });

                playerRef.current = player;
            }

            const player = playerRef.current;
            const source = `${import.meta.env.VITE_API_HOST}/videos/fetch/${channel}/${course}/${video}/main.m3u8`;

            player.src({
                src: source,
                type: 'application/x-mpegURL',
                withCredentials: true,
            });

            if (totalLength && startProgress) {
                if (totalLength > startProgress) {
                    player.currentTime(startProgress);
                } else {
                    player.currentTime(0);
                }
            }

            const onPlay = () => {
                const progress = player.currentTime();
                const totalLength = player.duration();
                trackProgress({ video, channel, course, progress, totalLength });

                // Update metadata
                if ('mediaSession' in navigator) {
                    navigator.mediaSession.metadata = new MediaMetadata({
                        artist: course,
                        title: video,
                    });
                }
            };

            const trackOnStop = () => {
                const progress = player.currentTime();
                const totalLength = player.duration();
                trackProgress({ video, channel, course, progress, totalLength });

                // Reset metadata
                if ('mediaSession' in navigator) {
                    navigator.mediaSession.metadata = new MediaMetadata({});
                }
            }

            const localOnEnd = () => {
                const progress = player.currentTime();
                const totalLength = player.duration();
                trackProgress({ video, channel, course, progress, totalLength });
                onEnd({ progress, totalLength });
            };

            player.on('ended', localOnEnd);
            player.on('pause', () => {
                userPaused = Boolean(player.userActive());
            });
            player.on('playing', () => {
                userPaused = false;
            });

            document.addEventListener('visibilitychange', function () {
                if (document.visibilityState === 'hidden' && !userPaused) {
                    try {
                        player.play();
                    } catch (e) {
                        console.warn('Cannot play video while page is hidden.', e);
                    }
                }
            });

            window.addEventListener('beforeunload', trackOnStop);
            window.addEventListener('pagehide', trackOnStop);

            player.on('pause', trackOnStop);
            player.on('seeking', trackOnStop);
            player.on('play', onPlay);

            return () => {
                player.off('play', onPlay);
                player.off('ended', localOnEnd);
                player.off('pause', trackOnStop);
                player.off('pause', () => {
                    userPaused = Boolean(player.userActive());
                });
                player.off('playing', () => {
                    userPaused = false;
                });
                document.removeEventListener('visibilitychange', function () {
                    if (document.visibilityState === 'hidden' && !userPaused) {
                        try {
                            player.play();
                        } catch (e) {
                            console.warn('Cannot play video while page is hidden.', e);
                        }
                    }
                });
                window.removeEventListener('beforeunload', trackOnStop);
                window.removeEventListener('pagehide', trackOnStop);
            };
        }
    }, [channel, course, video, onEnd, trackProgress, startProgress, totalLength, setVideoProgress]);

    return (
        <div className="video-container">
            <video ref={videoRef} className="embed-responsive-item video-js vjs-default-skin" />
        </div>
    );
};

export default VideoPlayer;