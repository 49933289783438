import React from 'react';
import styles from "./main.module.css";
import {Row} from "react-bootstrap";
import ResponsiveImage from "./components/responsive-image";
import {Link, Outlet, useParams} from "react-router-dom";
import LoginButton from "./components/login-button";
import headerLogoStyles from '~/header-logo.module.css';

export const Footer = () =>
    <section>
        <div className={styles.footerMargin}/>
        <footer className={styles.footer}>
            <span className={styles.footer}>© 2024 Renew You</span>
            <Link to="/contact-us">Contact Us</Link>
        </footer>
    </section>;

export const AuthLayout = () => (
    <div>
        <section className={styles.authLayoutWrapper}>
            <Row>
                <header className={styles.logoWrapper}>
                    <Link to="/">
                        <ResponsiveImage className={styles.authLayoutLogo}
                                         alt="Renew You Logo"
                                         basePath="/images"
                                         src="renewyou-logo"
                        />
                    </Link>
                </header>
            </Row>
            <Row>
                <main><Outlet/></main>
            </Row>
        </section>
        <Footer/>
    </div>
);

interface SimpleLayoutProps {
    headerText?: string;
}

export const SimpleLayout: React.FC<SimpleLayoutProps> = ({headerText}) => {
    const { channel } = useParams<{ channel: string }>();
    return <>
        <header className={styles.simpleLayoutHeader}>
            <nav className={styles.simpleLayoutHeaderTextWrapper}>
                <Link to="/">
                    {!channel && <ResponsiveImage className={styles.logo}
                                     alt="Renew You"
                                     basePath="/images"
                                     src="renewyou-logo"
                    />}
                    {channel && <img className={headerLogoStyles.headerLogo} alt="Feedable"
                                     src={`/course-info/${channel}/channel-logo-light.png`}/>}
                </Link>
                {headerText && <h1 className={styles.headerText}>{headerText}</h1>}
            </nav>

            <LoginButton/>
        </header>
        <main><Outlet/></main>
        <Footer/>
    </>
}