import React, { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import useForm from './hooks/use-form';
import useErrors from './hooks/use-errors';
import useReCaptcha from '../hooks/use-recaptcha';
import useSignUpUser from './hooks/use-sign-up-user';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import formStyles from "../styles/form.module.css";
import { Alert } from "react-bootstrap";

type SignUpFormData = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
};

const SignUpForm: React.FC = () => {
    const [formData, handleChange] = useForm<SignUpFormData>({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [errors, addError, clearErrors] = useErrors();
    const { mutate, isLoading, isSuccess } = useSignUpUser();
    const navigate = useNavigate();
    const { verifyRecaptcha } = useReCaptcha();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        clearErrors();

        try {
            const token = await verifyRecaptcha('signup');
            mutate({ ...formData, token }, {
                onSuccess: (response) => {
                    if (response.errors) {
                        response.errors.forEach((error: string) => addError(error));
                    } else {
                        navigate('/login?code=registrationSuccess');
                    }
                },
            });
        } catch (error) {
            addError((error as Error).message);
        }
    };

    const renderErrors = () => {
        return errors.map((error, index) => (
            <Alert key={index} variant="danger">{error}</Alert>
        ));
    };

    return (
        <Form className={formStyles.form} onSubmit={handleSubmit}>
            <h2>Sign Up</h2>
            <Form.Group>
                <Form.Label>First Name:</Form.Label>
                <Form.Control required type="text" name="firstName" value={formData.firstName} onChange={handleChange} data-custom-name="firstName" />
            </Form.Group>
            <Form.Group>
                <Form.Label>Last Name:</Form.Label>
                <Form.Control required type="text" name="lastName" value={formData.lastName} onChange={handleChange} data-custom-name="lastName" />
            </Form.Group>
            <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control required type="email" name="email" value={formData.email} onChange={handleChange} data-custom-name="email" />
            </Form.Group>
            <Form.Group>
                <Form.Label>Password:</Form.Label>
                <Form.Control required type="password" name="password" value={formData.password} onChange={handleChange} data-custom-name="password" />
            </Form.Group>
            <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
            </Button>
            <p style={{ display: "flex", alignItems: "center" }}>
                Already have an account?
                <Button variant="link" href='/login'>Sign In</Button>
            </p>
            {!isLoading && isSuccess && errors.length > 0 && <section>{renderErrors()}</section>}
        </Form>
    );
};

export default SignUpForm;
