import React, {useState, useEffect} from 'react';
import {Form, Button, Container, Row, Col, Alert} from 'react-bootstrap';
import {useParams, useNavigate} from 'react-router-dom';
import useFetchEnrollment from '../hooks/use-fetch-enrollment';
import useFetchCourse from '../hooks/use-fetch-course';
import useCheckoutSession from './hooks/use-checkout-session';
import styles from './course-info-page.module.css';
import useUserStatus from "../hooks/use-user-status";
import ResponsiveImage from "../components/responsive-image";

const CourseInfoPage: React.FC = () => {
    const {channel, course: courseParam, discountCode} = useParams<{
        channel: string,
        course: string,
        discountCode?: string
    }>();
    const navigate = useNavigate();

    // Ensure parameters are defined
    if (!channel || !courseParam) {
        return <p>Error: Missing channel or course parameters.</p>;
    }

    const {
        isAuthenticated,
        loading: userLoading,
        error: fetchUserError,
        email
    } = useUserStatus();
    const {isEnrolled, isLoading: enrollmentLoading} = useFetchEnrollment(email, channel, courseParam);
    const {courseData, loading: courseLoading, fetchCourseError} = useFetchCourse(channel, courseParam);
    const {mutate: submitEmail, data: sessionData, error: submitError, isLoading: submitLoading} = useCheckoutSession();
    const [emailInput, setEmailInput] = useState<string>('');

    useEffect(() => {
        if (!userLoading && isAuthenticated) {
            setEmailInput(email || "");
        }
    }, [userLoading, isAuthenticated, email]);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (isEnrolled) {
            if (!isAuthenticated) {
                navigate('/login');
            } else {
                window.location.href = `/player/${channel}/${courseParam}`;
            }
        } else {
            submitEmail({email: emailInput, channel, course: courseParam, discountCode});
        }
    };

    useEffect(() => {
        if (sessionData) {
            if (sessionData.isAuthorized) {
                setTimeout(() => {
                    window.location.href = `/login?code=alreadyHasAccess&callback=player/${channel}/${courseParam}`;
                }, 2000);
            } else if (sessionData.sessionUrl) {
                setTimeout(() => {
                    window.location.href = sessionData.sessionUrl;
                }, 2000);
            }
        }
    }, [sessionData, channel, courseParam]);

    if (courseLoading) {
        return <p>Loading course information...</p>;
    }

    if (fetchCourseError) {
        return <p>Error loading course information: {fetchCourseError.toString()}</p>;
    }

    if (fetchUserError) {
        return <p>Error loading user information: {fetchUserError.toString()}</p>;
    }

    const offerPrice = courseData && discountCode && courseData.promotionPrice ? courseData.promotionPrice : null;

    return (
        <Container className={styles.container}>
            <Row>
                <Col md={6}>
                    {courseData &&
                        <ResponsiveImage className={styles.fvaByhoImg}
                                         basePath={`/course-info/${channel}`}
                                         src={courseParam}
                                         alt="Belt Your Heart Out"/>
                    }
                </Col>
                <Col md={6} className={styles.formWrapper}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEmail" className={styles.formGroup}>
                            <Row>
                                <Col xs={8}>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                        required
                                        disabled={isAuthenticated}
                                        className={styles.customInput} // Apply custom CSS module class
                                    />
                                </Col>
                                <Col xs={4} className="d-flex align-items-end">
                                    <Button variant="primary" type="submit"
                                            disabled={userLoading || enrollmentLoading || submitLoading}>
                                        {isEnrolled ? 'View Course' : 'Checkout'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    {submitError && <Alert variant="danger" className="mt-3">{submitError.message}</Alert>}
                    {sessionData && sessionData.isAuthorized &&
                        <Alert variant="info" className="mt-3">You are already enrolled please login to access the
                            course...</Alert>}
                    {sessionData && sessionData.sessionUrl &&
                        <Alert variant="info" className="mt-3">Redirecting to secure checkout...</Alert>}
                    {courseData && (
                        <article className={styles.description}>
                            {offerPrice && (
                                <div className={styles.offerPrice}>
                                    <p className={styles.specialOffer}>Special Offer: {offerPrice}</p>
                                    <p className={styles.normalPrice}>Normal Price: {courseData.price}</p>
                                </div>
                            )}
                            {!offerPrice && (
                                <p className={styles.normalPrice}>Price: {courseData.price}</p>
                            )}
                            <p>{courseData.description}</p>
                        </article>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default CourseInfoPage;