import { useMutation, UseMutationResult, MutationFunction } from 'react-query';

type VerifyEmailPayload = {
    email: string;
    code: string;
};

type DataObject = {
    success: boolean;
};

type VerifyEmailResponse = {
    success: boolean;
    data: DataObject;
};

const useVerifyEmail = (options = {}): UseMutationResult<
    VerifyEmailResponse,
    Error,
    VerifyEmailPayload
> => {
    const mutationFn: MutationFunction<
        VerifyEmailResponse,
        VerifyEmailPayload
    > = async (data) => {
        const response = await fetch(`${import.meta.env.VITE_API_HOST}/auth/verify-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Failed to verify email');
        }
        return await response.json();
    };
    return useMutation(mutationFn, options);
};

export default useVerifyEmail;
