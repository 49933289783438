import React from 'react';
import styles from "./header.module.css";
import headerLogoStyles from '~/header-logo.module.css';
import LoginButton from "../components/login-button";

interface HeaderProps {
    course: {
        title: string;
    };
    channel: string;
}

const Header: React.FC<HeaderProps> = ({channel, course}) => {
    return (
        <nav className={styles.nav}>
            <div className={styles.headerWrapper}>
                <a href={`/courses/${channel}`}>
                    <img className={headerLogoStyles.headerLogo} alt="Feedable" src={`/course-info/${channel}/channel-logo-dark.png`}/>
                </a>
                <h1 className={styles.headerText}>{course.title}</h1>
            </div>
            <LoginButton />
        </nav>
    );
};

export default Header;