import { useState, ChangeEvent } from 'react';

type FormData = {
    [key: string]: string;
};

const useForm = <T extends FormData>(initialState: T) => {
    const [formData, setFormData] = useState<T>(initialState);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return [formData, handleChange] as const;
};

export default useForm;
