import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import {Link} from "react-router-dom";

const ThankYouMessage: React.FC = () => {
    return (
        <Container className="mt-5">
            <Alert variant="success">
                <Alert.Heading>Thank You for Your Purchase!</Alert.Heading>
                <p>
                    Please check your email for a password reset link. We appreciate your business and hope you enjoy
                    our product!
                </p>
                <p>
                    If you do not receive your email click <a href="/request-password-reset">here</a> to reset your
                    password.
                </p>
                <p>
                    If you are having issues accessing your course please reach out to us <Link to="/contact-us">Contact Us</Link>.
                </p>
            </Alert>
        </Container>
    );
};

export default ThankYouMessage;
