// frontend/src/not-found/not-found.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    navigate('/');
                    return 100;
                }
                return prev + 20;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [navigate]);

    return (
        <Container className="text-center mt-5">
            <Row>
                <Col>
                    <h1>Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist. You will be redirected to the home page in 5 seconds.</p>
                    <ProgressBar now={progress} label={`${progress}%`} />
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;