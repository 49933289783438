import React from 'react';
import ReactDOM from 'react-dom/client';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import './index.css';
import EmailPasswordLogin from './sign-in/email-password';
import EmailPasswordSignUp from './sign-up/email-password';
import VerifyEmailForm from './verify-email/verify-email-form';
import Home from './home/home';
import RequestReset from './reset-password/request-reset';
import ResetPassword from './reset-password/reset-password';
import UploadVideoForm from './upload-video/upload-video-form';
import ThankYou from './thank-you/thank-you';
import CourseInfoPage from './course-info/course-info-page';
import CourseListPage from "./course-list/course-list-page";
import {AuthLayout, SimpleLayout} from "./layout";
import VideoList from "./video-list/video-list";
import ContactForm from "./contact-us/contact-form";
import CoursePage from "./course-player/course-page";
import {AuthProvider} from "./AuthContext";
import NotFound from './not-found/not-found';

const queryClient = new QueryClient();
const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

const App = () => (
    <QueryClientProvider client={queryClient}>
        <React.StrictMode>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/player/:channel/:course/:video?" element={<CoursePage/>}/>
                        <Route path="/" element={<Home/>}/>
                        <Route element={<AuthLayout/>}>
                            <Route path="/upload" element={<UploadVideoForm/>}/>
                            <Route path="/thankyou" element={<ThankYou/>}/>
                            <Route path="/verify-email" element={<VerifyEmailForm/>}/>
                            <Route path="/request-password-reset" element={<RequestReset/>}/>
                            <Route path="/reset-password/:email" element={<ResetPassword/>}/>
                            <Route path="/contact-us" element={
                                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                                    <ContactForm/>
                                </GoogleReCaptchaProvider>
                            }/>
                            <Route path="/login" element={
                                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                                    <EmailPasswordLogin/>
                                </GoogleReCaptchaProvider>
                            }/>
                            <Route
                                path="/signup"
                                element={
                                    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                                        <EmailPasswordSignUp/>
                                    </GoogleReCaptchaProvider>
                                }/>
                        </Route>

                        <Route element={<SimpleLayout />}>
                            <Route path="/course/:channel/:course/:discountCode?" element={<CourseInfoPage/>}/>
                            <Route path="/videos/:channel/list" element={<VideoList/>}/>
                        </Route>

                        <Route element={<SimpleLayout headerText="Courses"/>}>
                            <Route path="/courses/:channel" element={<CourseListPage/>}/>
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </React.StrictMode>
    </QueryClientProvider>
);

ReactDOM.createRoot(document.getElementById('root')!).render(<App/>);