import React, {ReactElement, useState} from "react";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import formStyles from "../styles/form.module.css"
import {Alert} from "react-bootstrap";
import useResetPassword, {ResetPasswordResponse} from "./useResetPassword";
import {useNavigate, useParams} from "react-router-dom";
import {ChevronCompactLeft} from "react-bootstrap-icons";

interface ResponseMessage {
    message: string;
    variant: "danger" | "success";
}

interface FormData {
    newPassword: string;
    confirmNewPassword: string;
}

export default function ResetPassword() : ReactElement {
    const [responseMessage, setResponseMessage] = React.useState< ResponseMessage | null>(null);
    const [formData, setFormData] = useState<FormData>({
        newPassword: '',
        confirmNewPassword: ''
    });

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') as string;

    const { email} = useParams();

    if (!email || !token) {
       window.location.href = "/";
       throw "Email and token required";
    }

    const resetPasswordMutation = useResetPassword({
        onSuccess: function () {
            setResponseMessage({message: "Success! Redirecting to login..", variant: "success"});
            setTimeout(() => navigate("/login"), 1000);

        },
        onError: function (response: ResetPasswordResponse) {
            if (response.status === 429) {
                setResponseMessage({message: "Too many request have been sent. Please try again later.", variant: "danger"});
            } else {
                setResponseMessage({message: "Sorry there was an error requesting your password reset. Please try again later.", variant: "danger"});
            }
        }
    });

    const handleResetRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formData.newPassword === formData.confirmNewPassword) {
            resetPasswordMutation.mutateAsync({
                password: formData.newPassword, token

            });
        } else {
            setResponseMessage({message: "Passwords do not match..", variant: "danger"});
        }

    };

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <Form className={formStyles.form} onSubmit={handleResetRequest}>
            <h2>Reset your password</h2>
            <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control disabled type="email" name="email" value={email} />
            </Form.Group>
            <Form.Group>
                <Form.Label>New password:</Form.Label>
                <Form.Control required type="password" name="newPassword" value={formData.newPassword}
                              onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Confirm new password:</Form.Label>
                <Form.Control required type="password" name="confirmNewPassword" value={formData.confirmNewPassword}
                              onChange={handleChange}/>
            </Form.Group>
            <Button variant="primary"
                    type="submit">{resetPasswordMutation.isLoading ? 'Loading...' : 'Reset Password'}</Button>
            <Button variant="link" href='/login'><ChevronCompactLeft/>Back to sign-in</Button>
            {responseMessage && <Alert variant={responseMessage.variant}>{responseMessage.message}</Alert>}
        </Form>
    );
}
