import { useMutation, UseMutationResult } from 'react-query';

// Define a type for the error response
interface ErrorResponse {
    message: string;
}

const useCheckoutSession = (): UseMutationResult<any, ErrorResponse, { email: string; channel: string; course: string; discountCode?: string }> => {
    return useMutation(async (params: { email: string; channel: string; course: string; discountCode?: string }) => {
        const response = await fetch(`${import.meta.env.VITE_API_HOST}/checkout/course`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'An error occurred while submitting the email.');
        }

        return response.json();
    });
};

export default useCheckoutSession;
