import styles from "./home.module.css";
import LoginButton from "../components/login-button";
import ResponsiveImage from "../components/responsive-image";
import { Footer } from "../layout";
import { Button } from 'react-bootstrap';

export default function Home() {
    return (
        <div className={styles.body + " bg-gradient"}>
            <main className={styles.main}>
                <header className={styles.header}>
                    <ResponsiveImage className={styles.logo}
                                     alt="Feedable"
                                     basePath="/images"
                                     src="renewyou-logo" />
                    <div>
                        <LoginButton/>
                    </div>
                </header>
                <div className={styles.content}>
                    <div className={styles.leftMain}>
                        <h1 className={styles.heading}>Your <br />Gateway to Wellness<br /> Mastery</h1>
                        <p className={styles.info}>The most secure streaming platform for Health and Wellness course
                            creators.</p>
                        <h3 style={{color: "red"}}>Explore Channels</h3>

                        <ul className={styles.unstyledList}>
                            <li>
                                <Button className={styles.channelLogoButton}
                                        variant="outline-primary"
                                        href='/courses/fiercevoiceacademy/'>
                                    <img className={styles.channelLogo} alt="Fierce Voice Academy Logo"
                                         src={`/course-info/fiercevoiceacademy/channel-logo-light.png`}/>
                                </Button>
                            </li>
                            <li>
                                <Button className={styles.channelLogoButton}
                                        variant="outline-primary"
                                        href='/courses/feedable'>
                                    <img className={styles.channelLogo} src="/images/feedable-logo-2024-crop.png" alt="Feedable Channel Logo"/>
                                </Button>
                            </li>
                        </ul>




                    </div>
                    <div className={styles.rightMain}>
                        <ResponsiveImage alt="Photo of a woman singing in front of a teacher on a TV."
                                         className={styles.mainImage}
                                         basePath="images"
                                         src="sing"/>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}