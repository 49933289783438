import { useQuery } from 'react-query';

interface UserStatus {
    isAuthenticated: boolean;
    email: string;
}

const fetchUserStatus = async (): Promise<UserStatus> => {
    const response = await fetch('/api/user/info');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

interface UseUserStatusResult {
    isAuthenticated: boolean;
    loading: boolean;
    error: Error | null;
    email: string | null;
}

const useUserStatus = (): UseUserStatusResult => {
    const { data, isLoading, error } = useQuery<UserStatus, Error>('userStatus', fetchUserStatus);

    return {
        isAuthenticated: data ? data.isAuthenticated : false,
        loading: isLoading,
        error: error ? error : null,
        email: data ? data.email : null
    };
};

export default useUserStatus;
