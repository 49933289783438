import React from 'react';
import { useParams } from 'react-router-dom';
import {useAuth} from '../AuthContext';
import useFetchEnrollmentWithParams from '../hooks/use-fetch-enrollment-with-params';
import styles from './course-list-page.module.css';
import ResponsiveImage from "../components/responsive-image";

const CourseListPage: React.FC = () => {
    const {isAuthenticated, user} = useAuth();
    const {isLoading, error, fetchEnrollment} = useFetchEnrollmentWithParams();
    const { channel } = useParams<{ channel: string }>();

    const handleClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const id = event.currentTarget.id;
        const [channel, course] = id.split('-');
        const email = user?.email;
        if (!email) {
            window.location.href = `/course/${channel}/${course}`;
        } else {
            try {
                const {data: enrollmentStatus} = await fetchEnrollment(email, channel, course);
                if (isLoading) {
                    console.log('Loading enrollment data...');
                    return;
                }

                if (error) {
                    console.error('Error fetching enrollment data:', error);
                    alert('Error fetching enrollment data. Please try again.');
                    return;
                }

                if (isAuthenticated && enrollmentStatus) {
                    window.location.href = `/player/${channel}/${course}`;
                } else {
                    window.location.href = `/course/${channel}/${course}`;
                }
            } catch (error) {
                console.error('Error during fetching enrollment:', error);
                window.location.href = `/course/${channel}/${course}`;
            }
        }

    };

    return (
        <>
            {channel === 'fiercevoiceacademy' && <section className={styles.courses}>
                <div className={styles.imageContainer}>
                    <a id="fiercevoiceacademy-holisticsinger" href="/course/fiercevoiceacademy/holisticsinger" onClick={handleClick} >
                        <ResponsiveImage basePath="/course-info/fiercevoiceacademy"
                                         src="holisticsinger"
                                         alt="Holistic Singer"/>

                    </a>
                </div>
                <div className={styles.imageContainer}>
                    <a id="fiercevoiceacademy-byho" href="/course/fiercevoiceacademy/byho" onClick={handleClick}>
                        <ResponsiveImage basePath="/course-info/fiercevoiceacademy"
                                         src="byho"
                                         alt="Belt Your Heart Out"/>
                    </a>
                </div>
            </section>}
            {channel !== 'fiercevoiceacademy' && <h1>Coming soon...</h1> }
        </>
    );
};

export default CourseListPage;