import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import Header from './header';
import VideoPlayer from './video-player';
import PlaylistLinks from './playlist-links';
import styles from './course-page.module.css';
import { Footer } from '../layout';
import { useVideoProgressStore } from './stores/video-progress-store';
import { useUpdateVideoProgress, useGetLastWatchedVideo, useSaveLastWatchedVideo } from './hooks/use-video-progress';
import PdfViewer from './pdf-viewer';

const CoursePage: React.FC = () => {
    const { channel = '', course = '', video = '' } = useParams<{ channel: string; course: string; video?: string }>();
    const navigate = useNavigate();
    const [courseData, setCourseData] = useState<any>(null);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { isAuthenticated, isLoading } = useAuth();
    const setVideoProgress = useVideoProgressStore((state) => state.setVideoProgress);
    const { mutate: trackProgress } = useUpdateVideoProgress();
    const { data: lastWatchedVideo, isLoading: isLastWatchedLoading } = useGetLastWatchedVideo(channel, course);
    const { mutate: saveLastWatchedVideo } = useSaveLastWatchedVideo();

    useEffect(() => {
        if (channel && course) {
            fetch(`/course-info/${channel}/${course}.json`)
                .then(response => response.json())
                .then(setCourseData)
                .catch(console.error);
        }
    }, [channel, course]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            fetch(`/api/user/authorized/${channel}/${course}/`, {
                credentials: 'include',
                headers: { 'Cache-Control': 'no-cache' }
            })
                .then(response => response.json())
                .then(data => setIsAuthorized(data.isAuthorized))
                .catch(console.error);
        } else if (!isLoading) {
            navigate(`/login?channel=${channel}&course=${course}`);
        }
    }, [isAuthenticated, isLoading, channel, course, navigate]);

    useEffect(() => {
        document.documentElement.setAttribute('data-bs-theme', 'dark');
        return () => document.documentElement.removeAttribute('data-bs-theme');
    }, []);

    useEffect(() => {
        if (courseData && !isLastWatchedLoading) {
            if (!video) {
                if (lastWatchedVideo) {
                    navigate(`/player/${channel}/${course}/${lastWatchedVideo}`, { replace: true });
                } else {
                    navigate(`/player/${channel}/${course}/${courseData.playList[0].path}`, { replace: true });
                }
            } else if (courseData && video) {
                const currentVideo = courseData.playList.find((v: any) => v.path === video);
                if (currentVideo) {
                    document.title = `${courseData.title} - ${currentVideo.title}`;
                    saveLastWatchedVideo({ channel, course, video });
                }
            }
        }
    }, [channel, course, video, courseData, lastWatchedVideo, isLastWatchedLoading, navigate, saveLastWatchedVideo]);

    const handleVideoEnd = ({ totalLength, progress }: { totalLength: number, progress: number }) => {
        if (courseData && video) {
            trackProgress({ video, channel, course, progress, totalLength }, {
                onSuccess: () => setVideoProgress(video, progress, totalLength)
            });

            const currentIndex = courseData.playList.findIndex((v: any) => v.path === video);
            const nextIndex = currentIndex + 1;
            if (nextIndex < courseData.playList.length) {
                const nextVideo = courseData.playList[nextIndex].path;
                setVideoProgress(nextVideo, 0, 0);
                trackProgress({ video: nextVideo, channel, course, progress: 0, totalLength: 0 });
                navigate(`/player/${channel}/${course}/${nextVideo}`, { replace: true });
            }
        }
    };

    if (isLoading || isLastWatchedLoading) return <div>Loading...</div>;

    const contentInfo = getContentInfo(courseData.playList, video);
    const currentIndex = courseData?.playList.findIndex((v: any) => v.path === video);
    const nextVideo = currentIndex !== undefined && currentIndex + 1 < courseData.playList.length
        ? courseData.playList[currentIndex + 1].path
        : undefined;

    return (
        <section className={styles.coursePage}>
            {courseData && <Header channel={channel} course={{ title: courseData.title }} />}
            <main className="body">
                {courseData && (
                    <section className={styles.player}>
                        {isAuthorized ? (
                            <>
                                <div className={styles.playerWrapper}>
                                    {contentInfo && contentInfo.type === 'video' &&
                                        <VideoPlayer channel={channel}
                                                     course={course}
                                                     video={video}
                                                     onEnd={handleVideoEnd} />
                                    }
                                    {contentInfo && contentInfo.type === 'pdf' &&
                                        <PdfViewer channel={channel}
                                                   course={course}
                                                   file={video}
                                                   nextVideo={nextVideo} />
                                    }
                                </div>
                                <PlaylistLinks links={courseData.playList} channel={channel} course={course} video={video} />
                            </>
                        ) : (
                            <p>Redirecting to secure checkout...</p>
                        )}
                    </section>
                )}
            </main>
            <Footer />
        </section>
    );
};

interface Content {
    path: string;
    title: string;
    previewImage: string;
    type: string;
}

function getContentInfo(links: Array<Content>, content: string) {
    return links.find((link) => link.path === content);
}

export default CoursePage;