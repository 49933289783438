import { useState } from 'react';

const useErrors = () => {
    const [errors, setErrors] = useState<string[]>([]);

    const addError = (error: string) => {
        setErrors((prevErrors) => [...prevErrors, error]);
    };

    const clearErrors = () => {
        setErrors([]);
    };

    return [errors, addError, clearErrors] as const;
};

export default useErrors;
