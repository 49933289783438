import { useMutation, UseMutationResult, MutationFunction } from 'react-query';

export type ResendCodePayload = {
    email: string;
};

export type ResendCodeResponse = {
    status: number;
};

const useResendCode = (options = {}): UseMutationResult<
    ResendCodeResponse,
    ResendCodeResponse,
    ResendCodePayload
> => {
    const mutationFn: MutationFunction<
        ResendCodeResponse,
        ResendCodePayload
    > = async (data) => {
        const response = await fetch(`${import.meta.env.VITE_API_HOST}/auth/resend-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw response;
        }
        return await response.json();
    };
    return useMutation(mutationFn, options);
};

export default useResendCode;
