import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const fetchEnrollmentData = async (email: string, channel: string, course: string) => {
    const response = await fetch(`${import.meta.env.VITE_API_HOST}/user/enrollment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, channel, course }),
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.isEnrolled;
};

const useFetchEnrollment = (email?: string | null, channel?: string, course?: string) => {
    const [params, setParams] = useState<{ email?: string | null, channel?: string, course?: string }>({ email, channel, course });

    const { data: isEnrolled, isLoading, error, refetch } = useQuery(
        ['enrollment', params.email, params.channel, params.course],
        () => fetchEnrollmentData(params.email!, params.channel!, params.course!),
        {
            enabled: !!params.email && !!params.channel && !!params.course,
        }
    );

    useEffect(() => {
        if (email && channel && course) {
            setParams({ email, channel, course });
        }
    }, [email, channel, course]);

    useEffect(() => {
        if (params.email && params.channel && params.course) {
            refetch();
        }
    }, [params, refetch]);

    return { isEnrolled, isLoading, error };
};

export default useFetchEnrollment;