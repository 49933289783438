// File: frontend/src/course-player/stores/videoProgressStore.ts
import create from 'zustand';

export interface VideoProgressState {
    videoProgress: Record<string, { progress: number; totalLength: number }>;
    setVideoProgress: (video: string, progress: number, totalLength: number) => void;
}

export const useVideoProgressStore = create<VideoProgressState>((set) => ({
    videoProgress: {},
    setVideoProgress: (video, progress, totalLength) => set((state) => ({
        videoProgress: {
            ...state.videoProgress,
            [video]: { progress, totalLength },
        },
    })),
}));