// hooks/use-fetch-enrollment-with-params.ts
import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

const fetchEnrollmentData = async (email: string, channel: string, course: string) => {
    const response = await fetch(`${import.meta.env.VITE_API_HOST}/user/enrollment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, channel, course }),
    });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.isEnrolled;
};

const useFetchEnrollmentWithParams = () => {
    const queryClient = useQueryClient();
    const [params, setParams] = useState<{ email?: string | null, channel?: string, course?: string }>({});

    const queryResult = useQuery(
        ['enrollment', params.email, params.channel, params.course],
        () => fetchEnrollmentData(params.email!, params.channel!, params.course!),
        {
            enabled: !!params.email && !!params.channel && !!params.course,
        }
    );

    const fetchEnrollment = async (email: string, channel: string, course: string) => {
        setParams({ email, channel, course });
        await queryClient.invalidateQueries(['enrollment', email, channel, course]);
        return queryResult.refetch();
    };

    return {
        ...queryResult,
        fetchEnrollment,
    };
};

export default useFetchEnrollmentWithParams;
