import React from 'react';
import { Button } from 'react-bootstrap';
import { Power } from 'react-bootstrap-icons';
import useUserStatus from '../hooks/use-user-status';
import { useAuth } from '../AuthContext';

const LoginButton: React.FC = () => {
    const { isAuthenticated, loading } = useUserStatus();
    const { login, logout } = useAuth();

    const handleLoginLogout = async () => {
        if (isAuthenticated) {
            await logout();
        } else {
            login();
        }
    };

    return (
        !loading && (
            <Button onClick={handleLoginLogout}>
                {isAuthenticated ? <Power size={24} /> : 'Login'}
            </Button>
        ) || null
    );
};

export default LoginButton;