import { useQuery, useMutation } from 'react-query';
import { useVideoProgressStore } from '../stores/video-progress-store';

interface VideoProgressParams {
    video: string;
    channel: string;
    course: string;
    progress: number;
    totalLength: number;
}

const updateVideoProgress = async ({ video, channel, course, progress, totalLength }: VideoProgressParams) => {
    const response = await fetch(`${import.meta.env.VITE_API_HOST}/videos/track-progress/${channel}/${course}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ video, progress, totalLength }),
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Error updating video progress');
    }

    return response.json();
};

export const useUpdateVideoProgress = () => {
    return useMutation(updateVideoProgress);
};

interface VideoProgress {
    progress: number;
    totalLength: number;
    video: string;
}

const fetchVideoProgress = async (channel: string, course: string): Promise<Array<VideoProgress>> => {
    const response = await fetch(`${import.meta.env.VITE_API_HOST}/videos/progress/${channel}/${course}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Error fetching video progress');
    }
    return response.json();
};

export const useGetVideoProgress = (channel: string, course: string) => {
    const setVideoProgress = useVideoProgressStore((state) => state.setVideoProgress);
    const videoProgress = useVideoProgressStore((state) => state.videoProgress);

    useQuery(['videoProgress', channel, course], async () => {
        const items = await fetchVideoProgress(channel, course);
        items.forEach((items) => {
            const {video, progress, totalLength} = items;
            setVideoProgress(video, progress, totalLength);
        });
    });

    return videoProgress;
};

// Add this function to save the last watched video
const saveLastWatchedVideo = async ({ channel, course, video }: { channel: string, course: string, video: string }) => {
    const response = await fetch(`${import.meta.env.VITE_API_HOST}/videos/last-watched`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ channel, course, video }),
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Error saving last watched video');
    }

    return response.json();
};

// Add this function to fetch the last watched video
const fetchLastWatchedVideo = async (channel: string, course: string): Promise<{ video: string }> => {
    const response = await fetch(`${import.meta.env.VITE_API_HOST}/videos/last-watched/${channel}/${course}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Error fetching last watched video');
    }

    return response.json();
};

// Export the hooks
export const useSaveLastWatchedVideo = () => {
    return useMutation(saveLastWatchedVideo);
};

export const useGetLastWatchedVideo = (channel: string, course: string) => {
    return useQuery(['lastWatchedVideo', channel, course], () => fetchLastWatchedVideo(channel, course));
};