import { useMutation } from 'react-query';

type SignUpData = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    token: string;
};

const useSignUpUser = () => {
    return useMutation(async (data: SignUpData) => {
        const response = await fetch(`${import.meta.env.VITE_API_HOST}/auth/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        return response.json();
    });
};

export default useSignUpUser;
