import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useContactForm } from './hooks/use-contact-form';
import useReCaptcha from '../hooks/use-recaptcha';

interface ContactFormData {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    message: string;
}

const ContactForm: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<ContactFormData>();
    const { mutation, message, error } = useContactForm();
    const { verifyRecaptcha } = useReCaptcha();

    const onSubmit: SubmitHandler<ContactFormData> = async (data) => {
        try {
            const token = await verifyRecaptcha('contactus');
            // Append the reCAPTCHA token to the form data
            const formDataWithRecaptcha = { ...data, recaptchaToken: token };
            mutation.mutate(formDataWithRecaptcha);
        } catch (error) {
            console.error('reCAPTCHA verification failed', error);
        }
    };

    return (
        <Container>
            <h1>Contact Us</h1>
            {message && <Alert variant="success">{message}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="email" className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        {...register('email', { required: 'Email is required' })}
                        isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="firstName" className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter your first name"
                        {...register('firstName', { required: 'First name is required' })}
                        isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.firstName?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="lastName" className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter your last name"
                        {...register('lastName', { required: 'Last name is required' })}
                        isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.lastName?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="phoneNumber" className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        type="tel"
                        placeholder="Enter your phone number"
                        {...register('phoneNumber')}
                    />
                </Form.Group>

                <Form.Group controlId="message" className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter your message"
                        {...register('message', { required: 'Message is required' })}
                        isInvalid={!!errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.message?.message}
                    </Form.Control.Feedback>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={mutation.isLoading}>
                    {mutation.isLoading ? 'Sending...' : 'Submit'}
                </Button>
            </Form>
        </Container>
    );
};

export default ContactForm;
