import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScrollMode, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { Button } from 'react-bootstrap';
import styles from './pdf-viewer.module.css';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';

// Set the workerSrc property
GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js';

interface PdfViewerProps {
    channel: string;
    course: string;
    file: string;
    nextVideo?: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ channel, course, file, nextVideo }) => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await fetch(`${import.meta.env.VITE_API_HOST}/videos/fetch/${channel}/${course}/${file}/${file}.pdf`, {
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch PDF');
                }
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                setPdfUrl(url);

                const loadingTask = getDocument(url);
                const pdfDocument = await loadingTask.promise;
                setPageCount(pdfDocument.numPages);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError('An unknown error occurred');
                }
            }
        };

        fetchPdf();

        return () => {
            if (pdfUrl) {
                URL.revokeObjectURL(pdfUrl);
            }
        };
    }, [channel, course, file]);

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: () => file,
    });
    const { Download } = getFilePluginInstance;

    const scrollModePluginInstance = scrollModePlugin();
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

    const goToPreviousPage = () => {
       jumpToPreviousPage();
    };

    const goToNextPage = () => {
        jumpToNextPage();
    };

    const handlePageChange = (e: { currentPage: any; }) => {
        const { currentPage } = e;
        setCurrentPage(currentPage+1);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!pdfUrl) {
        return <div>Loading PDF...</div>;
    }

    return (
        <>
            <div className={styles.toolbar}>
                <Download>
                    {({ onClick }: RenderDownloadProps) => (
                        <Button onClick={onClick}>Download</Button>
                    )}
                </Download>
                {nextVideo && (
                    <Button onClick={() => navigate(`/player/${channel}/${course}/${nextVideo}`)}>
                       Close
                    </Button>
                )}
            </div>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div className={styles.viewerContainer}>
                    <Viewer
                        fileUrl={pdfUrl}
                        plugins={[
                            getFilePluginInstance,
                            scrollModePluginInstance,
                            pageNavigationPluginInstance
                        ]}
                        scrollMode={ScrollMode.Horizontal}
                        defaultScale={SpecialZoomLevel.PageFit}
                        onPageChange={handlePageChange}
                    />
                </div>
                {pageCount > 1 &&
                    <nav className={styles.pdfViewerNav}>
                        <Button onClick={goToPreviousPage} disabled={currentPage === 1}>Previous Page</Button>
                        {currentPage} / {pageCount}
                        <Button onClick={goToNextPage} disabled={currentPage === pageCount}>Next Page</Button>
                    </nav>
                }

            </Worker>
        </>
    );
};

export default PdfViewer;